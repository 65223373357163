import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicMetaService {
  platformId: Object;
  private renderer: Renderer2;

  constructor(
    @Inject(PLATFORM_ID) platFormId: Object,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
    ) {
      this.renderer = rendererFactory.createRenderer(null, null);
    this.platformId = platFormId;
  }

  loadMeta(meta_info: any) {
    if (isPlatformBrowser(this.platformId)) {
      // Client-side logic
      let dom = new DOMParser().parseFromString(meta_info, 'text/html');
      let meta_data = dom.head.children;
      let resultDom = [];
      for (let meta of Array.from(meta_data)) {
        resultDom.push(window.document.getElementsByTagName('head')[0].appendChild(meta));
      }
      return resultDom;
    } else if (isPlatformServer(this.platformId)) {
      // Server-side logic
      this.document.head.innerHTML += meta_info;
    }
  }

  unLoadMeta(meta_data: any) {
    if (isPlatformBrowser(this.platformId)) {
      for (let meta of meta_data) {
        window.document.getElementsByTagName('head')[0].removeChild(meta);
      }
    }
  }

}
