import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { LocalStorageService } from './storage/local-storage.service';
import { CountryDetail } from '../models/profile.mode';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { PanelService } from './api/panel.service';

const STORE_REGION_KEY = 'store-region';
const DEFAULT_REGION_CODE = 'GLC';

@Injectable({
  providedIn: 'root',
})
export class StoreRegionService {
  private localCountryList: CountryDetail[];
  private platformId: object;

  constructor(
    private localStorageService: LocalStorageService,
    private panelService: PanelService,
    @Inject(PLATFORM_ID) platFormId: Object
  ) {
    this.platformId = platFormId;
  }

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    return this.resolveInfo(route);
  }

  public get countryList() {
    return this.localCountryList;
  }

  public get selectedRegion(): CountryDetail {
    return this.localStorageService.getItem(STORE_REGION_KEY);
  }

  public setRegion(region: CountryDetail) {
    if (!region) return;
    this.localStorageService.setItem(STORE_REGION_KEY, region);
  }

  public setRegionById(id: number) {
    const region = this.getCountryByParam('id', id);
    if (!region) return;
    this.setRegion(region);
  }

  private resolveInfo(route: ActivatedRouteSnapshot): Promise<any> {
    if (isPlatformServer(this.platformId)) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.panelService.getCountryList({ ordering: 'name' }).subscribe({
        next: (data) => {
          this.setCountries(data);
          resolve('');
        },
        error: () => {
          resolve('');
        },
      });
    });
  }

  private setCountries(list: CountryDetail[]) {
    // Move 'Global' to first position in array
    list.unshift(
      list.splice(
        list.findIndex((item) => item.code === DEFAULT_REGION_CODE),
        1
      )[0]
    );
    this.localCountryList = list;

    if (!this.selectedRegion) {
      this.seUserRegion();
    }
  }

  private seUserRegion() {
    if (!this.countryList.length) return;
    this.setRegion(
      this.getCountryByParam('is_country_of_request', true) ||
        this.getCountryByParam('code', DEFAULT_REGION_CODE)
    );
  }

  private getCountryByParam(field: keyof CountryDetail, value: any) {
    return this.countryList.find((item) => item[field] === value);
  }
}
