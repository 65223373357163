import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CanonicalUrlService {
  private baseUrl = environment.production ? 'https://kingiftcard.com' : 'https://brokerplus.mana-card.com'; 
  constructor(
    private meta: Meta,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { 
    console.log('canonical url service');
    this.setupCanonicalLinks();
  }

  private setupCanonicalLinks() {
    if (isPlatformBrowser(this.platformId)) {
      // For client-side rendering - subscribe to route changes
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        console.log(event.urlAfterRedirects);
        const url = `${this.baseUrl}${event.urlAfterRedirects}`;
        this.updateCanonicalLink(url);
      });
    } else if (isPlatformServer(this.platformId)) {
      // For server-side rendering - set once based on initial route
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        take(1) // Take only the first NavigationEnd event on the server
      ).subscribe((event: NavigationEnd) => {
        console.log(event.urlAfterRedirects);
        const url = `${this.baseUrl}${event.urlAfterRedirects}`;
        this.updateCanonicalLink(url);
      });
    }
  }

  setCanonicalURL(url?: string) {
    const canonicalUrl = url || `${this.baseUrl}${this.router.url}`;
    this.updateCanonicalLink(canonicalUrl);    
    }

    private updateCanonicalLink(url: string) {
      if (isPlatformBrowser(this.platformId)) {
        // Client-side approach
        const existingLink = this.document.querySelector('link[rel="canonical"]');
        if (existingLink) {
          existingLink.setAttribute('href', url);
        } else {
          const link = this.document.createElement('link');
          link.setAttribute('rel', 'canonical');
          link.setAttribute('href', url);
          this.document.head.appendChild(link);
        }
      } else if (isPlatformServer(this.platformId)) {
        // Server-side approach using Meta service
        const linkTag = { rel: 'canonical', href: url };
        
        // Remove existing tag if any and add new one
        this.meta.removeTag('rel="canonical"');
        this.meta.addTag(linkTag);
      }
    }
    

  
}
