import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LocalStorageService } from '../storage/local-storage.service';
import { StateService } from '../storage/state.service';
import { ApiUrls, userAccountUrls } from '../../enum/routes';
import { StoreDetailService } from '../resolver/store-detail.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ApiKeySelector } from 'store.env';
import { ChannelSourceService } from '../channel-source/channel-source.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  platformId: Object;
  isServerSide: boolean = false;
  constructor(
    private localStorageService: LocalStorageService,
    private stateService: StateService,
    private storeResolver: StoreDetailService,
    private translateService: TranslateService,
    private channelSourceService: ChannelSourceService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.platformId = platformId;
    this.isServerSide = isPlatformServer(this.platformId);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentLang = this.translateService.currentLang;
    if (
      !req.url.includes(ApiUrls.searchProductByName) &&
      !req.url.includes(ApiUrls.trendingQueries) &&
      !req.url.includes(ApiUrls.cryptoGatewayInternal) &&
      (!(req.params.get('offset') && req.params.get('offset') > '0') ||
        req.url.includes(ApiUrls.category))
    ) {
      this.stateService.startLoading();
    }

    req = req.clone({
      setHeaders: this.channelSourceService.channelSourceHeader(req.url),
    });

    let accessToken = this.localStorageService.getItem('accessToken');
    if (this.checkAccessType(req, accessToken)) {
      req = req.clone({
        setHeaders: {
          'API-KEY': this.APIKEY,
          'Accept-Language': `${currentLang}`,
        },
      });
    } else if (this.isUserAccountUrl(req.url)) {
      const headers = {
        'project-api-key': this.storeResolver.permissionInit.client_id,
        'Accept-Language': `${currentLang}`,
        ...(accessToken && {
          Authorization: `Token ${accessToken}`,
        }),
      };

      req = req.clone({
        setHeaders: headers,
      });
    } else if (accessToken) {
      req = req.clone({
        setHeaders: {
          Token: `${accessToken}`,
          'API-KEY': this.APIKEY,
          'Accept-Language': `${currentLang}`,
        },
      });
    }

    return next.handle(req).pipe(
      tap((event) => {}),
      finalize(() => {
        this.stateService.endLoading();
      })
    );
  }

  checkAccessType(req, accessToken) {
    if (
      (((req.url.includes(ApiUrls.products) && !accessToken) ||
        req.url.includes(ApiUrls.googleLogin) ||
        req.url.includes(ApiUrls.appleLogin) ||
        req.url.includes(ApiUrls.smsLogin) ||
        req.url.includes(ApiUrls.smsVerification) ||
        req.url.includes(ApiUrls.exchangeDetail) ||
        req.url.includes(ApiUrls.gateway) ||
        req.url.includes(ApiUrls.category) ||
        (req.url.includes(ApiUrls.loginUrl) && !this.isRefreshToken(req.url)) ||
        req.url.includes(ApiUrls.signUp) ||
        req.url.includes(ApiUrls.storeCountries) ||
        req.url.includes(ApiUrls.countryCode) ||
        req.url.includes(ApiUrls.storeGroups) ||
        req.url.includes(ApiUrls.searchProductByName) ||
        req.url.includes(ApiUrls.storeDetail) ||
        req.url.includes(ApiUrls.FAQ) ||
        req.url.includes(ApiUrls.trendingQueries) ||
        (req.url.includes(ApiUrls.home) && !accessToken) ||
        (req.url.includes(ApiUrls.homes) && !accessToken)) &&
        !req.url.includes(ApiUrls.favorite) &&
        !req.url.includes('delete-favorite') &&
        !req.url.includes('add-favorite')) ||
      req.url.includes(ApiUrls.checkStockAvailability) ||
      req.url.includes(ApiUrls.logProductSearch)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private get APIKEY() {
    let storeApiKey;
    if (isPlatformBrowser(this.platformId)) {
      storeApiKey =
        this.localStorageService.getItem('store-key') || environment.clientId;
    }
    if (isPlatformServer(this.platformId)) {
      storeApiKey = process.env[ApiKeySelector];
    }
    return storeApiKey;
  }

  private isUserAccountUrl(url: string) {
    return Object.keys(userAccountUrls).some((key) =>
      url.includes(userAccountUrls[key])
    );
  }

  private isRefreshToken(reqUrl: string) {
    return reqUrl.includes(userAccountUrls.refreshToken);
  }
}
